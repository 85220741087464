import React from "react"

import Layout from "../components/Layout"
import { Link } from "gatsby"

import Slider from "../components/Slider"
import Projects from "../components/Projects"
import Testimonials from "../components/Testimonials"
import ContactForm from "../components/ContactForm"

import ilustra_pitch from "../images/ilustra_pitch.png"
import avatar_generico from "../images/avatar_generico.svg"

export default function Home() {
  return (
    <Layout breadcrumbs={["Home"]} isHome={true}>
      <section id="banner">
        <div className="inner">
          <Slider />
        </div>
      </section>

      {/* ABOUT SECTION */}
      <section id="main" className="wrapper">
        <div className="inner">
          <div className="row">
            {/* IMAGEM */}
            <div className="col-7 col-12-medium">
              <span className="image fitt"
              ><img src={ilustra_pitch} alt="ilustra pitch"
                /></span>
            </div>
            {/* TEXTO */}
            <div className="col-5 col-12-medium">
              <h2>Tecnologias Colaborativas</h2>

              <p>
                No Engajados, acreditamos que o papel da
                tecnologia é articular e conectar pessoas e comunidades em
                configurações que promovam a emergência da inteligência e da
                sensibilidade humana colaborativa.
              </p>

              <p>
                Nossa missão é desenvolver plataformas e metodologias capazes de
                facilitar e estimular o engajamento colaborativo e o protagonismo
                criativo em torno de propósitos compartilhados de impacto positivo.
              </p>

              {/* <a href="#" className="button primary icon fa">></a> */}
            </div>
          </div>
        </div>
      </section>

      {/* METODOLOGIA */}
      <section id="cta2" className="wrapper">
        <div className="inner cant-see-it-section metodologia-section">
          <div className="row">
            <div className="col-1 col-12-medium"></div>
            <div className="col-5 col-12-medium">
              <h2>Nossa metodologia</h2>
              <p>
                (a) Nossa forma de trabalhar
                <br />
                <br />
                Gostamos de trabalhar de maneira colaborativa, iterativa e modular. 
                Entendemos que as soluções digitais devem respeitar e valorizar as características e processos das iniciativas, 
                sempre buscando viabilizar e promover a criatividade de suas comunidades.
                <br />
                <br />
                Acompanhamos as iniciativas em uma jornada de reflexão sobre objetivos e prototipação, 
                a fim de assegurarmos a implementação de ferramentas computacionais que sirvam ao seu propósito. 
              </p>
            </div>

            <div className="col-5 col-12-medium">
              <br />
              <br />

              <h4></h4>
              <div className="text-mobile">
                <ul>
                  (b) Nossa forma de projetar
                  <br />
                  <br />
                  Gostamos de projetar redes e funcionalidades que promovam
                  a colaboração e o protagonismo por meio de uma experiência de
                  usuário didática, transversal (entre públicos internos e externos),
                  engajante e útil.
                  <br />
                  <br />
                  Nossa meta é sempre empoderar o usuário com experiências narrativas
                  e ferramentas flexíveis, sem sacrificar a facilidade de uso.
                </ul>
              </div>
              {/* <a href="#" className="button primary icon fa">></a> */}
            </div>
          </div>
        </div>
      </section>

      {/* dorothy da Dorothy */}
      <section id="cta" className="wrapper">
        <div className="inner cant-see-it-section dorothy-section">
          <div className="row">
            <div className="col-1 col-12-medium"></div>
            <div className="col-5 col-12-medium">
              <h2>Framework Dorothy</h2>
              <p>
              Para viabilizar nossa proposta de valor, concebemos e desenvolvemos a Dorothy: 
              um conjunto aberto de tecnologias e boas práticas para a construção de plataformas colaborativas, 
              em torno de fluxos de informação e decisão. 
              </p>
              <p>
              A arquitetura da Dorothy é capaz de refletir e contribuir para a organização das mais diversas formas de 
              configuração de redes humanas, promovendo uma articulação flexível, convidativa, cooperativa e eficiente.
              </p>
            </div>

            <div className="col-5 col-12-medium">
              <br />
              <br />

              <h4></h4>
              <div className="cta-list">
                <ul>
                  <li>Organização em grupos de trabalho;</li>
                  <li>Processos colaborativos orgânicos;</li>
                  <li>Experiência engajante;</li>
                  <li>Protagonização e storytelling;</li>
                  <li>Modularidade e customização;</li>
                  <li>Design por emergência (bottom-up);</li>
                  <li>Orquestração de redes;</li>
                  <li>Rastreabilidade do impacto;</li>
                  <li>Open Source.</li>
                </ul>
                </div>
            </div>
          </div>
        </div>
      </section>

      <Projects />

      {/* Testimonials ou Depoimentos */}
      <section id="dp" className="wrapper">
        <div className="inner">
          <header className="special">
            <h2>O que dizem nossos parceiros</h2>
            <p>
              Palavras de quem esteve conosco nas jornadas de desenvolvimento de
              soluções.
            </p>
          </header>
          <div className="testimonials">

            <Testimonials />

          </div>
        </div>
      </section>

      {/* dorothy do BLOG na HOME */}
      <section id="ctablog" className="wrapper">
        <div className="inner">
          <h2>engajados.org.br</h2>
          <h3>Aguardem novidades...</h3>
        </div>
      </section>

      {/* EQUIPE HOME */}
      <section id="time" className="wrapper">
        <div className="inner">
          <header className="special">
            <h2>Equipe</h2>
          </header>

          <div className="content">
            <div className="row">
              <div className="col-1 col-12-medium"></div>
              <div className="col-5 col-12-medium">
                <p style={{ textAlign: 'right' }}>
                  <span className="image right2">
                    <img
                      src={avatar_generico}
                      data-clip="hexagon"
                      alt="foto Ricardo"
                    /></span>
                  <br />
                  <br />
                  <strong>Ricardo Zylbergeld</strong>
                  <br />
                  <span>co-fundador</span>
                  <br />
                  <Link className="button small" to="/founders/ricardo">Perfil</Link>
                </p>
              </div>

              <div className="col-5 col-12-medium">
                <p style={{ textAlign: 'left' }}>
                  <span className="image left2">
                    <img
                      src={avatar_generico}
                      data-clip="hexagon"
                      alt="foto Tiago"
                    />
                  </span>
                  <br />
                  <br />
                  <strong>Tiago Lazier</strong>
                  <br />
                  <span>co-fundador</span>
                  <br />
                  <Link className="button small" to="/founders/tiago">Perfil</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="serv"></div>
      <ContactForm />
    </Layout>
  )
}

import React, { useEffect, useState } from 'react';

import aspas_out from "../images/aspas_out.svg"
import avatar_1 from "../images/icones/Henriqueta.jpeg"
import avatar_2 from "../images/icones/rafael.jpg"
import avatar_3 from "../images/icones/Mariana.jpg"

const testimonialAll = [0, 1, 1, 1, 1, 2, 3];

export default function Testimonials() {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        let chosenTestimonials = [];
        let options = [...testimonialAll];

        while(chosenTestimonials.length < 3 && options.length >= 0) {
            const chosen = options[Math.floor(Math.random() * Math.floor(options.length))];
            options = options.filter( item => item != chosen );
            chosenTestimonials.push(chosen);

        }

        setTestimonials(chosenTestimonials);
    }, []);

    return (
        <>
            {/* {testimonials.includes(0) && */}
                <section>
                <div className="content">
                    <blockquote>
                        <div className="quotein"></div>
                        <p>
                            O Instituto Engajados trouxe para os trabalhos não apenas
                            a sua competência técnica, como todo o comprometimento
                            com as causas socioambientais foco da nossa Organização.
                
                    <img
                                alt="image"

                                src={aspas_out}
                                style={{ verticalAlign: 'top' }}
                                width="45px"
                            />
                        </p>
                    </blockquote>
                    <br></br>
                    <br></br>
                    <div className="author">
                        <div className="image">
                            <img
                                alt="image"
                                width= "180%"
                                src={avatar_1}
                                data-clip="hexagon"
                                alt=""
                            />
                        </div>
                        <p className="credit">
                            <strong>Maria Henriqueta A. Raymundo</strong><br /><span>ANPPEA</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* }

            {testimonials.includes(1) && */}
                <section>
                <div className="content">
                    <blockquote>
                        <div className="quotein"></div>
                        <p>
                            Com as ferramentas e funcionalidades desenvolvidas pelo Engajados
                            estamos conseguindo potencializar a colaboração social, presencial
                            e virtual, para a busca de soluções para os desafios das nossas cidades.
                    <img
                                alt="image"

                                src={aspas_out}
                                style={{ verticalAlign: 'top' }}
                                width="45px"
                            />
                        </p>
                    </blockquote>
                    <div className="author">
                        <div className="image">
                            <img
                                alt="image"
                                src={avatar_2}
                                data-clip="hexagon"
                                alt=""
                            />
                        </div>
                        <p className="credit">
                            <strong>Rafael Jó Girão</strong><br /><span>Instituto Agir Ambiental</span>
                        </p>
                    </div>
                </div>
                </section> 
            {/*}

             {testimonials.includes(2) &&  */}<section>
                <div className="content">
                    <blockquote>
                        <div className="quotein"></div>
                        <p>
                        O Engajados potencializou ainda mais os processos colaborativos na rede Origens Brasil. 
                        Nos trouxe novas ideias, novas tecnologias, ferramentas e soluções, 
                        em um processo constante de co-criação.
                    <img
                                alt="image"

                                src={aspas_out}
                                style={{ verticalAlign: 'top' }}
                                width="45px"
                            />
                        </p>
                    </blockquote>
                    <div className="author">
                        <div className="image">
                            <img
                                alt="image"

                                src={avatar_3}
                                data-clip="hexagon"
                                alt=""
                            />
                        </div>
                        <p className="credit">
                            <strong>Mariana Finotti</strong><br /><span>Origens Brasil® / Imaflora</span>
                        </p>
                    </div>
                </div>
            </section>

            {/* testimonials.includes(3) && <section>
                <div className="content">
                    <blockquote>
                        <div className="quotein"></div>
                        <p>
                            Desde (4) a nossa primeira reunião até a fase de implementação
                            das ferramentas sempre fomos bem acompanhados pela equipe
                            Engajados, que traziam métodos e conhecimentos vitais ao
                            processo como um todo.
        <img
                                alt="image"

                                src={aspas_out}
                                style={{ verticalAlign: 'top' }}
                                width="45px"
                            />
                        </p>
                    </blockquote>
                    <div className="author">
                        <div className="image">
                            <img
                                alt="image"

                                src={avatar_generico}
                                data-clip="hexagon"
                                alt=""
                            />
                        </div>
                        <p className="credit">
                            <strong>Eulino Zappi</strong><br /><span>- CFO no WhatsApp</span>
                        </p>
                    </div>
                </div>
            </section>} */}
        </>
    );
}
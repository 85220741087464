import React, { useState } from 'react'

import axios from 'axios'
import contact from '../images/contact.png'

export default function ContactForm() {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [newsletter, setNewsletter] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);

    const sendContact = async (e) => {
        e.preventDefault();

        console.log(name, email, message, newsletter);

        // emailError, verificar
        if (email.trim() === '') {
            setEmailError(true);
            return;
        }

        // enviar
        setSending(true);

        const result = await axios.post(`${process.env.SERVER_URL}/general-services/contact`, {
            from: email,
            subject: '',
            message,
        });

        setSending(false);

        setName('');
        setEmail('');
        setMessage('');
        setNewsletter(false);
        setEmailError(false);

        // notificação
        setSent(true);
        setTimeout(() => setSent(false), 3000);
    }

    const emailChange = (e) => {
        setEmail(e.target.value);

        if (e.target.value.trim() === '') return;

        setEmailError(false);
    }

    return (
        <>
            {/* CALL TO ACTION ou contatoIÇOS */}
            <section id="main" className="wrapper">
                <div id="contato" className="inner">
                    <div className="row">
                        <div className="col-1 col-12-medium"></div>

                        {/* TEXTO */}
                        <div className="col-5 col-12-medium">
                            <h2>Gostou da nossa proposta de trabalho?<br></br><br></br>Entre em contato conosco:</h2>
                                <img src={contact} width="50%" />
                            {/* <p>
                                O engajados é uma iniciativa sem fins lucrativos que desenvolve
                                plataformas que facilitam e estimulam o engajamento colaborativo
                                e o protagonismo criativo em torno de propósitos compartilhados
                                de impacto positivo.
              </p>

                            <p>
                                Conedorothymos pessoas, espaços e ferramentas de maneira a promover
                                a emergência da sensibilidade e da inteligência humana
                                colaborativa.
              </p> */}
                        </div>

                        {/* FORMULARIO */}
                        <div className="col-5 col-12-medium">
                            <form onSubmit={sendContact} className="form-spaced">
                                <div className="row">
                                    {/* nome */}
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={name}
                                            placeholder="Nome"
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                    </div>

                                    {/* mail */}
                                    <div className="col-12">
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            value={email}
                                            placeholder="e-mail"
                                            onChange={emailChange}
                                            className={emailError ? 'field-error' : ''}
                                        />
                                    </div>

                                    {/* mensagem */}
                                    <div className="col-12">
                                        <textarea
                                            name="textarea"
                                            id="textarea"
                                            placeholder="Deixe aqui uma mensagem para nossa equipe"
                                            rows="6"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}></textarea>
                                    </div>

                                    {/* check receber newsletter */}
                                    {/* <div className="col-12" onClick={() => setNewsletter(!newsletter)}>
                                        <input
                                            type="checkbox"
                                            id="checkbox-alpha"
                                            name="checkbox"
                                            checked={newsletter}
                                            onChange={console.log}
                                        />
                                        <label>Quero receber as newsletters</label>
                                    </div> */}

                                    {sent && <div className="col-12">
                                        <h3>Mensagem enviada com sucesso!</h3>
                                    </div>}

                                    {/* botões */}
                                    <div className="col-12">
                                        <ul className="actions">
                                            <li>
                                                <input disabled={sending} type="submit" value="Enviar" className="primary" />
                                            </li>
                                            {/* <li><input type="reset" value="Refazer" /></li> */}
                                        </ul>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
import React from 'react';

import { Link } from "gatsby"

import origens from "../images/origens-projeto_r.jpg"
import territorio from "../images/territorio/araucaria_r2.png";
import monitoaea from "../images/monitoraea_4.png"
import engajados from "../images/engajadoslab_2.jpg"
import avina from "../images/avina/avina-projeto_r2.jpg"
import vft from "../images/vft/vft-projeto_r.jpg"

export default function Projects() {
    return (
        <section id="pj" className="wrapper">
            <div className="inner">
                <header className="special">
                    <h2>Projetos em Destaque</h2>
                    <p>
                        Confira alguns dos projetos que já utilizam a Dorothy e
                        outras ferramentas desenvolvidas pelo Engajados.
                    </p>
                </header>

                <div className="highlights">
                    <section className="box">
                        <div className="image fit"><img src={origens} alt="" /></div>
                        <div className="barrinha"></div>
                        <div className="content">
                            <header>
                                <h3>Origens Brasil<span className="registered">®</span></h3>
                            </header>
                            <p className="projetos">
                                Administrado pelo Imaflora, o projeto promove negócios
                                sustentáveis na Amazônia, em áreas prioritárias de conservação.
                            </p>
                            <ul className="actions fit">
                                <li>
                                    <Link className="button primary fit small" to="/projetos/origens">
                                        VER PROJETO
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section className="box">
                        <div className="image fit">
                            <img src={territorio} alt="" />
                        </div>
                        <div className="barrinha"></div>
                        <div className="content">
                            <header>
                                <h3 className="territorio">Território em Desenvolvimento</h3>
                            </header>
                            <p className="projetos1">
                                Implementada pela Fundação Avina, a plataforma
                                inova no tratamento didático e colaborativo que dá aos indicadores sociais do IPS.
                            </p>
                            <ul className="actions fit">
                                <li>
                                    <Link className="button primary fit small" to="/projetos/territorio">
                                        VER PROJETO
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section className="box">
                        <div className="image fit">
                            <img src={monitoaea} alt="" />
                        </div>
                        <div className="barrinha"></div>
                        <div className="content">
                            <header>
                                <h3>MonitoraEA</h3>
                            </header>
                            <p className="projetos">
                                Coordenada pelo ANPPEA, a plataforma mapeia, monitora e
                                articula políticas e ações de educação ambiental por todo o Brasil.
                            </p>
                            <ul className="actions fit">
                                <li>
                                    <Link className="button primary fit small" to="/projetos/monitoraea">
                                        VER PROJETO
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section className="box">
                        <div className="image fit">
                            <img src={vft} alt="" />
                        </div>
                        <div className="barrinha"></div>
                        <div className="content">
                            <header>
                                <h3>VFT Lab</h3>
                            </header>
                            <p className="projetos">
                                Com uma metodologia do Imaflora/Idesam, o projeto
                               monitora o risco de desmatamento na cadeia produtiva da borracha.
                            </p>
                            <ul className="actions fit">
                                <li>
                                    <Link className="button primary fit small" to="/projetos/vftlab">
                                        VER PROJETO
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section className="box">
                        <div className="image fit">
                            <img src={avina} alt="" />
                        </div>
                        <div className="barrinha"></div>
                        <div className="content">
                            <header>
                                <h3>Avina Collect</h3>
                            </header>
                            <p className="projetos">
                                O Coletor, desenvolvido para a Fundação Avina,
                                é uma aplicação para a coleta de dados georreferenciados
                                online e offline.
                            </p>
                            <ul className="actions fit">
                                <li>
                                    <Link className="button primary fit small" to="/projetos/avina">
                                        VER PROJETO
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </section>

                    <section className="box">
                        <div className="image fit">
                            <img src={engajados} alt="" />
                        </div>
                        <div className="barrinha"></div>
                        <div className="content">
                            <header>
                                <h3>EngajadosLab</h3>
                            </header>
                            <p className="projetos">
                                Pensamos, aprimoramos, e desenvolvemos nossas tecnologias
                                com apoio de um grupo de pesquisa multidisciplinar.
                            </p>
                            <ul className="actions fit">
                                <li>
                                    <Link className="button primary fit small" to="/projetos/lab">
                                        VER PROJETO
                                    </Link>                                </li>
                            </ul>
                        </div>
                    </section>

                </div>
            </div>

            {/* dorothy separador verde */}
            <div id="barrona"></div>
        </section>
    );
}
import React, { useEffect, useState } from 'react'

import { Link } from "gatsby"

import scroll_left from "../images/scroll_left.svg"
import scroll_right from "../images/scroll_right.svg"
import bannerpic1 from "../images/bannerpic1_r.jpg"
import lab1 from "../images/lab2.png"
import bannerpic3 from "../images/origens-slide_r.jpg"
import origensLogo from "../images/origens_logo_4.png"
import monitora1 from "../images/monitora2.png"
import monitora2 from "../images/monitora4.png"
import territorio1 from "../images/territorio1_r.png"
import territorio2 from "../images/territorio6.png"

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';


export default function Slider() {
    const lastSlide = 3;

    const [currentSlide, setCurrentSlide] = useState(0);

    const previous = () => {
        if (currentSlide === 0) setCurrentSlide(lastSlide);
        else setCurrentSlide(currentSlide - 1);
    }
    const next = () => {
        if (currentSlide === lastSlide) setCurrentSlide(0);
        else setCurrentSlide(currentSlide + 1);
    }

    const updateCurrentSlide = (idx) => {
        if (idx === currentSlide) return;

        if (idx > lastSlide) setCurrentSlide(0);
        else setCurrentSlide(1);
    }

    useEffect(() => {
        console.log('currentSlide', currentSlide)
    }, [currentSlide])

    return (
        <>
            {/* BUTTONS */}
            <div id="prevnext" style={{ zIndex: 10 }}>
                <span>
                    <a href="#" className="left" id="previous_slide" onClick={previous}>
                        <img src={scroll_left} alt="anterior" />
                    </a>
                </span>
                <span>
                    <a href="#" className="right" id="next_slide" onClick={next}>
                        <img src={scroll_right} alt="próxima" />
                    </a>
                </span>
            </div>

            <Carousel
                autoPlay={true}
                infiniteLoop={true}
                selectedItem={currentSlide}
                onChange={updateCurrentSlide}
                showArrows={false}
                showIndicators={true}
                showStatus={false}
                interval={10000}
                stopOnHover={true}
            >
                <div className="row">
                    {/* Chamadas destaque */}
                    <div className="col-1 col-12-medium"></div>
                    <div className="col-5 col-12-medium">
                        <h1>Origens Brasil<span className="registered">®</span></h1>
                        <div className="barrinha"></div>
                        <p>
                            O Engajados é membro da coordenação técnica de todas as plataformas do premiado projeto do Imaflora. <br /><br />
                            <Link className="button primary icon fa-eye to-front desktop-only" to="/projetos/origens">
                                SAIBA MAIS
                            </Link>
                        </p>
                    </div>

                    {/* grid fotos */}
                    <div className="col-5 col-12-medium">
                        <div className="box alt">
                            <div className="row gtr-50 gtr-uniform">
                                <div className="col-8">
                                    <span className="image fit2"><img
                                        src={bannerpic3}
                                        data-clip="hexagon"
                                        alt="destaque grande banner"
                                    /></span>
                                </div>
                                <div className="col-4">
                                    <span className="image fit2"><img
                                        src={origensLogo}
                                        data-clip="hexagon"
                                        alt="destaque peq banner"
                                    /></span>
                                </div>
                            </div>
                        </div>
                        <p>
                            <Link className="button primary icon fa-eye to-front mobile-only" to="/projetos/origens">
                                SAIBA MAIS
                            </Link>
                        </p>
                    </div>
                    <div className="col-1 col-12-medium"></div>
                </div>

                <div className="row">
                    {/* Chamadas destaque */}
                    <div className="col-1 col-12-medium"></div>
                    <div className="col-5 col-12-medium">
                        <h1>Território em desenvolvimento</h1>
                        <div className="barrinha"></div>
                        <p>
                            Bons indicadores nos ajudam não apenas a entender melhor o mundo, mas também a transformá-lo!  <br />
                            <Link className="button primary icon fa-eye to-front desktop-only" to="/projetos/territorio">
                                SAIBA MAIS
                            </Link>
                        </p>
                    </div>

                    {/* grid fotos */}
                    <div className="col-5 col-12-medium">
                        <div className="box alt">
                            <div className="row gtr-50 gtr-uniform">
                                <div className="col-8">
                                    <span className="image fit2"><img
                                        src={territorio2}
                                        data-clip="hexagon"
                                        alt="destaque grande banner"
                                    /></span>
                                </div>
                                <div className="col-4">
                                    <span className="image fit2"><img
                                        src={territorio1}
                                        data-clip="hexagon"
                                        alt="destaque peq banner"
                                    /></span>
                                </div>
                            </div>
                        </div>
                        <p>
                            <Link className="button primary icon fa-eye to-front mobile-only" to="/projetos/territorio">
                                SAIBA MAIS
                            </Link>
                        </p>
                    </div>
                    <div className="col-1 col-12-medium"></div>
                </div>


                <div className="row">
                    {/* Chamadas destaque */}
                    <div className="col-1 col-12-medium"></div>
                    <div className="col-5 col-12-medium">
                        <h1>MonitoraEA</h1>
                        <div className="barrinha"></div>
                        <p>
                            O governo somos todos nós! E políticas públicas de educação ambiental podem ser promovidas por todos que amam o meio-ambiente. <br /><br />
                            <Link className="button primary icon fa-eye to-front desktop-only" to="/projetos/monitoraea">
                                SAIBA MAIS
                            </Link>
                        </p>
                    </div>

                    {/* grid fotos */}
                    <div className="col-5 col-12-medium">
                        <div className="box alt">
                            <div className="row gtr-50 gtr-uniform">
                                <div className="col-8">
                                    <span className="image fit2"><img
                                        src={monitora1}
                                        data-clip="hexagon"
                                        alt="destaque grande banner"
                                    /></span>
                                </div>
                                <div className="col-4">
                                    <span className="image fit2"><img
                                        src={monitora2}
                                        data-clip="hexagon"
                                        alt="destaque peq banner"
                                    /></span>
                                </div>
                            </div>
                        </div>
                        <p>
                            <Link className="button primary icon fa-eye to-front mobile-only" to="/projetos/monitoraea">
                                SAIBA MAIS
                            </Link>
                        </p>
                    </div>
                    <div className="col-1 col-12-medium"></div>
                </div>

                <div className="row">
                    {/* Chamadas destaque */}
                    <div className="col-1 col-12-medium"></div>
                    <div className="col-5 col-12-medium">
                        <h1>EngajadosLab</h1>
                        <div className="barrinha"></div>
                        <p>
                            Pesquisa & Desenvolvimento multidisciplinar que pensa a tecnologia como facilitadora da inteligência humana colaborativa. <br /><br />
                            <Link className="button primary icon fa-eye to-front desktop-only" to="/projetos/lab">
                                SAIBA MAIS
                            </Link>
                        </p>
                    </div>

                    {/* grid fotos */}
                    <div className="col-5 col-12-medium">
                        <div className="box alt">
                            <div className="row gtr-50 gtr-uniform">
                                <div className="col-8">
                                    <span className="image fit2"><img
                                        src={bannerpic1}
                                        data-clip="hexagon"
                                        alt="destaque grande banner"
                                    /></span>
                                </div>
                                <div className="col-4">
                                    <span className="image fit2"><img
                                        src={lab1}
                                        data-clip="hexagon"
                                        alt="destaque peq banner"
                                    /></span>
                                </div>
                            </div>
                        </div>
                        <p>
                            <Link className="button primary icon fa-eye to-front mobile-only" to="/projetos/lab">
                                SAIBA MAIS
                            </Link>
                        </p>
                    </div>
                    <div className="col-1 col-12-medium"></div>
                </div>

            </Carousel>
        </>
    );
}